import "./contactme.css";
import { contactinfo } from "../../contactinfo";
import { useContext, useEffect } from "react";
import elementContext from "../../elementContext";
import { isBrowser } from "react-device-detect";
import MobileNavbar from "../navigation/MobileNavbar";

export default function ContactMe() {
  //Consuming the element active context so that we can highlight the correct navigation item

  const { setElementActive } = useContext(elementContext);
  const activatenavigate = (value) => setElementActive(value);

  //Context consumption end

  useEffect(() => {
    activatenavigate("contactme");
  });
  return (
    <>
      {isBrowser ? void 1 : <MobileNavbar></MobileNavbar>}
      <div className="rightsidebar">
        <div className={isBrowser ? "contactme " : "contactme mobileversion"}>
          <h1 className="heroText">Lets Connect At !!!!</h1>
          <h3 className="emailaddr">Email: {contactinfo.email}</h3>
          <h3 className="phone">Phone: {contactinfo.phone}</h3>
        </div>
      </div>
    </>
  );
}
