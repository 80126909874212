import "./socials.css";
import { socials } from "../../config";
import { isBrowser } from "react-device-detect";

export default function Socials() {
  return (
    <div className="socials">
      <a
        href={socials.linkedin}
        className={isBrowser ? "sociallinks growElement" : "sociallinks"}
      >
        LINKEDIN
      </a>
      <a
        href={socials.github}
        className={isBrowser ? "sociallinks growElement" : "sociallinks"}
      >
        GITHUB
      </a>
    </div>
  );
}
