export default function Experience() {
  return (
    <div className="experience">
      <h3 className="Heading">EXPERIENCE</h3>
      <br />
      <div className="Section">
        <p>
          Worked At{" "}
          <strong className="highlightText">HCL Technologies LTD</strong> as a
          <strong className="highlightText"> Software Engineer</strong> from
          <strong className="highlightText"> Feb 2015 to Mar 2018</strong>
        </p>
        <p>
          Worked At
          <strong className="highlightText"> Genpact India Pvt LTD</strong> as a{" "}
          <strong className="highlightText">Management Trainee</strong> from{" "}
          <strong className="highlightText">May 2018 to Jun 2019</strong>.
        </p>
        <p>
          Currently working At{" "}
          <strong className="highlightText">Allstate Solutions Pvt LTD</strong>{" "}
          as a <strong className="highlightText">Technology Lead</strong> from
          <strong className="highlightText"> Jun 2019 till date</strong>.
        </p>
      </div>
    </div>
  );
}
