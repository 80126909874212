import project1 from "./images/project1.JPG";
import project2 from "./images/project2.JPG";
import project3 from "./images/project3.JPG";
import project4 from "./images/project4.JPG";
const projects = [
  {
    projectText: "Application with dropdown in react",
    image: project1,
    link: "https://gaurab019.github.io/dropdown-navigation-react/",
  },
  {
    projectText: "Rating Application in react",
    image: project2,
    link: "https://gaurab019.github.io/interactive-rating-using-React/",
  },
  {
    projectText: "Advice Generator using API",
    image: project3,
    link: "https://gaurab019.github.io/advice-generator-app-main/",
  },
  {
    projectText: "Responsive product review card",
    image: project4,
    link: "https://gaurab019.github.io/product-preview-card-component-main/",
  },
];

export { projects };
