import { useState } from "react";
import DownloadResume from "../DownloadResume";
import "./mobilenavbar.css";
import { NavElementsMobile } from "./NavElements";
import Socials from "./Socials";
import Menu from "../../images/icon-menu.svg";
import close from "../../images/icon-close-menu.svg";

export default function MobileNavbar() {
  const [expand, setexpand] = useState(false);
  const clickmenu = () => {
    setfirstload(false);
    setexpand(!expand);
  };
  const [firstload, setfirstload] = useState(true);

  return (
    <>
      <div className="mobilenavbar">
        {/* <img src={profilephoto} alt="Gaurab" className="photo" />
        <h1 className="name">GAURAB BHOWMICK</h1>{" "} */}
        <img src={Menu} alt="menu" className="menu" onClick={clickmenu} />
      </div>
      <div
        className={
          firstload
            ? "menudisable"
            : expand
            ? "overlaymenuenable"
            : "overlaymenudisable"
        }
      >
        <div className="rightsidemenu">
          <img src={close} alt="close" className="close" onClick={clickmenu} />
          <NavElementsMobile closemenu={clickmenu}></NavElementsMobile>
          <DownloadResume></DownloadResume>
          <Socials></Socials>
          <div className="emptyelement"></div>
        </div>
      </div>
    </>
  );
}
