export default function Bio() {
  return (
    <div className="bio">
      <h3 className="Heading">BIO</h3>
      <br />
      <div className="Section">
        <p>
          I am a{" "}
          <strong className="highlightText">Technology Enthusiast</strong>
        </p>
        <p>
          I have around <strong className="highlightText">8.5 years</strong> of
          experience in IT Industry.
        </p>
        <p>
          I have{" "}
          <strong className="highlightText">
            Banking and Insurance domain
          </strong>{" "}
          knowledge.
        </p>
        <p>
          I have worked on{" "}
          <strong className="highlightText">
            Web Based Technologies, RPA, Python{" "}
          </strong>
          etc.
        </p>
        <p>
          I am also interested on{" "}
          <strong className="highlightText">
            Financial Planning, Stock Market Analysis
          </strong>{" "}
          etc.
        </p>
      </div>
    </div>
  );
}
