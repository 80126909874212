import "./aboutme.css";
import "../commonrightside.css";
import MobileNavbar from "../navigation/MobileNavbar";
import Experience from "./Experience";
import Skills from "./Skills";
import Bio from "./Bio";
import { useContext, useEffect } from "react";
import { isBrowser } from "react-device-detect";
import elementContext from "../../elementContext";

export default function AboutMe() {
  //Consuming the element active context so that we can highlight the correct navigation item

  const { setElementActive } = useContext(elementContext);
  const activatenavigate = (value) => setElementActive(value);

  //Context consumption end
  useEffect(() => {
    activatenavigate("aboutme");
  });

  return (
    <>
      {isBrowser ? void 1 : <MobileNavbar></MobileNavbar>}
      <div className="rightsidebar">
        <div className="aboutmecss">
          <Bio></Bio>
          <Experience></Experience>
          <Skills></Skills>
        </div>
      </div>
    </>
  );
}
