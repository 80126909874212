import "./skills.css";
import { SkillsData } from "../../skills";
import styled, { keyframes } from "styled-components";

export default function Skills() {
  return (
    <div className="skills">
      <h3 className="Heading skillheading">SKILLS</h3>
      <br />
      <div className="skilltable">
        {SkillsData.map((element) => {
          const frame = keyframes`
                                                  from {
                                                          width: 0%;
                                                  }
                                                  to {
                                                          width: ${element.progress}%;
                                                  }
                                              `;
          const ProgressBar = styled.div`
            // position: absolute;
            // top: 0%;
            // left: 0%;
            // background-color: #b70200;
            border: 1px solid black;
            background-color: #9b17ff;
            height: 5px;
            border-radius: 50px;
            animation: ${frame} 2s forwards;
          `;
          return (
            <div className="skillData">
              <h5 className="skillheader">{element.skillname}</h5>
              <div className="skilllevel">
                <ProgressBar></ProgressBar>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
