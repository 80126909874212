import "./works.css";
import { projects } from "../../projects";
import { useContext, useEffect } from "react";
import elementContext from "../../elementContext";
import { isBrowser } from "react-device-detect";
import MobileNavbar from "../navigation/MobileNavbar";

export default function Works() {
  //Consuming the element active context so that we can highlight the correct navigation item

  const { setElementActive } = useContext(elementContext);
  const activatenavigate = (value) => setElementActive(value);

  //Context consumption end

  useEffect(() => {
    activatenavigate("works");
  });

  return (
    <>
      {isBrowser ? void 1 : <MobileNavbar></MobileNavbar>}
      <div className="rightsidebar">
        <div className=" works">
          {projects.map((elements) => {
            return (
              <div className="workcards">
                <img
                  src={elements.image}
                  alt={elements.projectText}
                  className="workImg"
                />
                <h3 className="workDesc">{elements.projectText}</h3>
                <a href={elements.link} className="codesource growElement">
                  GOTO LINK
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
