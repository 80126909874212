import Resume from "../files/Resume.docx";
import "./downloadresume.css";
import { isBrowser } from "react-device-detect";

export default function DownloadResume() {
  return (
    <a href={Resume} download="Gaurab Resume.docx">
      {" "}
      <h3 className={isBrowser ? "download downloadStyling" : " download "}>
        DOWNLOAD RESUME
      </h3>
    </a>
  );
}
