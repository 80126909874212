import "./homepage.css";
import "../commonrightside.css";
import { isBrowser } from "react-device-detect";
import DownloadResume from "../DownloadResume";
import TypewriterComponent from "typewriter-effect";
import profilephoto from "../../images/myPhoto.jpeg";
import { Link } from "react-router-dom";
// import Testfile from "../../files/Resume.docx";
import { useContext, useEffect } from "react";
import elementContext from "../../elementContext";

export default function HomePage() {
  //Consuming the element active context so that we can highlight the correct navigation item

  const { setElementActive } = useContext(elementContext);
  const activatenavigate = (value) => setElementActive(value);

  //Context consumption end

  useEffect(() => {
    activatenavigate("Home");
  });
  return (
    <div className="rightsidebar homepagestyle">
      {isBrowser ? (
        <h1 className="herotext">HELLO,</h1>
      ) : (
        <>
          <img src={profilephoto} alt="Gaurab" className="mobilephoto" />

          <h1 className="herotext">I'm Gaurab</h1>
        </>
      )}
      <h3 className={isBrowser ? "typedtext" : "typedtext smallfontsize"}>
        <TypewriterComponent
          options={{ loop: true }}
          onInit={(typewriter) => {
            typewriter
              .pauseFor(500)
              .typeString("I AM A FRONT END DEVELOPER")
              .pauseFor(2500)
              .deleteAll()
              .pauseFor(500)
              .typeString("WITH BACK END KNOWLEDGE")
              .pauseFor(2500)
              .deleteAll()
              .pauseFor(500)
              .typeString("READ MORE BELOW")
              .pauseFor(4000)
              .deleteAll()
              .pauseFor(500)
              .start();
          }}
        />
      </h3>
      <p className="description">
        I have <strong className="highlightText">8.5 Years</strong> of
        Experience. <br /> Currently working with{" "}
        <strong className="highlightText">Allstate Solutions PVT Ltd</strong> as
        <strong className="highlightText"> Technology Lead </strong>
      </p>
      <div className="actionelements">
        <Link to="./works">
          <button
            className={
              isBrowser
                ? "button portfolio growElement"
                : "button mobilebttn hireme"
            }
            onClick={() => activatenavigate("aboutme")}
          >
            Portfolio
          </button>
        </Link>
        <Link to="./contactme">
          <button
            className={
              isBrowser
                ? "button hireme growElement"
                : "button mobilebttn hireme"
            }
            onClick={() => activatenavigate("contactme")}
          >
            Contact Me
          </button>
        </Link>
      </div>
      {isBrowser ? (
        void 1
      ) : (
        <button className="button mobilebttn portfolio">
          <DownloadResume></DownloadResume>
        </button>
      )}
    </div>
  );
}
