const SkillsData = [
  {
    skillname: "HTML",
    progress: 55,
  },
  {
    skillname: "CSS",
    progress: 55,
  },
  {
    skillname: "JAVASCRIPT",
    progress: 60,
  },
  {
    skillname: "REACT",
    progress: 45,
  },
  {
    skillname: "BLUEPRISM",
    progress: 90,
  },
  {
    skillname: "PYTHON",
    progress: 55,
  },
  {
    skillname: "SQL",
    progress: 70,
  },
];

export { SkillsData };
