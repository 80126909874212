import profilephoto from "../../images/myPhoto.jpeg";
import "./leftsidebar.css";
import { NavElementsDesktop } from "./NavElements";
import Socials from "./Socials";
import DownloadResume from "../DownloadResume";

export default function LeftSideBar() {
  return (
    <div className="leftsidebar">
      <img src={profilephoto} alt="Gaurab" className="profilephoto" />
      <h1 className="profilename">GAURAB BHOWMICK</h1>{" "}
      <NavElementsDesktop></NavElementsDesktop>
      <DownloadResume></DownloadResume>
      <Socials></Socials>
    </div>
  );
}
