import { Link } from "react-router-dom";
import "./navelements.css";
import { useContext } from "react";
import elementContext from "../../elementContext";

function NavElementsDesktop() {
  //Consuming context to set the correct active element in the navigation pane
  const { elementactive, setElementActive } = useContext(elementContext);
  const activatenavigate = (value) => setElementActive(value);

  return (
    <>
      {" "}
      <Link to="/">
        {" "}
        <h3
          className={
            elementactive === "Home"
              ? "subelements growElement navigateActive"
              : "subelements growElement"
          }
          onClick={(e) => {
            console.log(e);
            activatenavigate("Home");
          }}
        >
          HOME
        </h3>
      </Link>
      <Link to="/aboutme">
        {" "}
        <h3
          className={
            elementactive === "aboutme"
              ? "subelements navigateActive growElement"
              : "subelements growElement"
          }
          onClick={() => activatenavigate("aboutme")}
        >
          ABOUT ME
        </h3>
      </Link>{" "}
      <Link to="/works">
        {" "}
        <h3
          className={
            elementactive === "works"
              ? "subelements growElement navigateActive"
              : "subelements growElement"
          }
          onClick={() => activatenavigate("works")}
        >
          DIGITAL WORKS
        </h3>
      </Link>{" "}
      <Link to="/contactme">
        {" "}
        <h3
          className={
            elementactive === "contactme"
              ? "subelements growElement navigateActive"
              : "subelements growElement"
          }
          onClick={() => activatenavigate("contactme")}
        >
          CONTACT ME
        </h3>
      </Link>
    </>
  );
}

function NavElementsMobile({ closemenu }) {
  // const [elementactive, setElementActive] = useState(false);
  // const activatenavigate = (value) => setElementActive(value);
  return (
    <>
      {" "}
      <Link to="/">
        {" "}
        <h3 className={"subelements  mobileActive"} onClick={() => closemenu}>
          HOME
        </h3>
      </Link>
      <Link to="/aboutme">
        {" "}
        <h3 className={"subelements  mobileActive"} onClick={() => closemenu}>
          ABOUT ME
        </h3>
      </Link>{" "}
      <Link to="/works">
        {" "}
        <h3 className={"subelements  mobileActive"} onClick={() => closemenu}>
          DIGITAL WORKS
        </h3>
      </Link>{" "}
      <Link to="/contactme">
        {" "}
        <h3 className={"subelements  mobileActive"} onClick={() => closemenu}>
          CONTACT ME
        </h3>
      </Link>
    </>
  );
}

export { NavElementsDesktop };
export { NavElementsMobile };
