import "./App.css";
import LeftSideBar from "./components/navigation/LeftSideBar";
// import MobileNavbar from "./components/navigation/MobileNavbar";
import HomePage from "./components/pages/HomePage";
import VerticalDivider from "./components/VerticalDivider";
// import HorizontalDivider from "./components/HorizontalDivider";
import { Routes, Route } from "react-router-dom";
import AboutMe from "./components/pages/AboutMe";
import Works from "./components/pages/Works";
import ContactMe from "./components/pages/ContactMe";
import { BrowserView, MobileView } from "react-device-detect";
import { useState } from "react";
import elementContext from "./elementContext";
// import { useRoutes } from "react-router-dom";

function App() {
  const [elementactive, setElementActive] = useState("Home");

  return (
    <elementContext.Provider value={{ elementactive, setElementActive }}>
      <div>
        <BrowserView>
          <div className="App">
            <LeftSideBar></LeftSideBar>
            <VerticalDivider></VerticalDivider>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/aboutme" element={<AboutMe />} />
              <Route path="/works" element={<Works />} />
              <Route path="/contactme" element={<ContactMe />} />
            </Routes>
          </div>
        </BrowserView>
        <MobileView>
          <div className="AppMobile">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/aboutme" element={<AboutMe />} />
              <Route path="/works" element={<Works />} />
              <Route path="/contactme" element={<ContactMe />} />
            </Routes>
          </div>
        </MobileView>
      </div>
    </elementContext.Provider>
  );
}

export default App;
